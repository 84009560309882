import { observer } from "mobx-react";
import { OffersWithTransferToggleProps } from "components/flexComponents/Wizard3ppPWA/typings";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "@egds/react-core/spacing";

export const OffersWithTransferToggle = observer((props: OffersWithTransferToggleProps) => {
  const { formatText } = useLocalization();

  const { lobState } = props;
  const checkboxText = formatText(lobState.config.offersWithTransfer.labelText);

  const toggleCheckbox = () => {
    lobState.toggleOffersWithTransfer();
  };

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkLayoutGridItem colSpan="all">
        <div data-testid="offers-with-transfers">
          <UitkCheckbox id="offers-with-transfers" onChange={toggleCheckbox} checked={lobState.offersWithTransfer}>
            {checkboxText}
          </UitkCheckbox>
          <input
            type="hidden"
            name="trans"
            value={lobState.offersWithTransfer ? 1 : 0}
            data-testid="offers-with-transfers-input"
          />
        </div>
      </UitkLayoutGridItem>
    </UitkSpacing>
  );
});
